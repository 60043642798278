import React, { Component } from 'react';
import logo from '../images/logos.jpg';
//import czechiaFlag from '../images/czechia.png';
//import slovakiaFlag from '../images/slovakia.png';
//import hungaryFlag from '../images/hungary.png';
//import polandFlag from '../images/poland.png';
//import romaniaFlag from '../images/romania.png';
//import russiaFlag from '../images/russia.png';
//import ukraineFlag from '../images/ukraine.png';
//import euFlag from '../images/eu.png';
import codeOfConduct from '../POS-Media_Code_of_Conduct.pdf';
//import RCG from 'react-captcha-generator';
import LanguageVersions from '../LanguageVersions'

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props)
        this.state = {
            selectedOption: 'Discrimination & Harassment',
            text: ' ',
            email: '',
            isEmailValid: true,
            captcha: '',
            enterCaptcha: ' ',
            counter: 0,
            sending: false,
            language: 'en'
        }
        this.handleOptionChange = this.handleOptionChange.bind(this)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.handleTextChange = this.handleTextChange.bind(this)
        this.handleEmailChange = this.handleEmailChange.bind(this)
        this.validate = this.validate.bind(this)
        this.result = this.result.bind(this)
    }

    handleOptionChange(changeEvent) {
        this.setState({
            selectedOption: changeEvent.target.value
        });
    };

    validate() {
        var text = this.state.text.trim();
        if (text === '') {
            this.setState({ text });
            return false;
        }

        if (this.state.email !== '') {
            var email_regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            if (!email_regex.test(this.state.email)) {
                this.setState({ isEmailValid: false });
                return false;
            }
            else {
                this.setState({ isEmailValid: true });
            }
        }
        else this.setState({ isEmailValid: true });

        if (this.state.enterCaptcha === ' ') {
            this.setState({ enterCaptcha: "" });
            return false;
        }
        console.log(this.state.captcha, this.state.enterCaptcha);
        console.log(this.state.captcha !== this.state.enterCaptcha);
        if (this.state.captcha !== this.state.enterCaptcha) return false;

        return true;
    }

    async handleFormSubmit(formSubmitEvent) {
        formSubmitEvent.preventDefault();
        if (this.validate()) {
            this.setState({ sending: true });
            var response = await fetch('api/Email', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.state)
            });

            var result = await response.json();

            if (result) {
                alert(`${LanguageVersions[this.state.language].success}\n\n${LanguageVersions[this.state.language].thanks}`);
                this.setState({
                    selectedOption: 'Discrimination & Harassment',
                    text: ' ',
                    email: '',
                    isEmailValid: true,
                    enterCaptcha: ' ',
                    counter: this.state.counter + 1
                });
            }
            else {
                alert(LanguageVersions[this.state.language].errorSubmit);
            }
            this.setState({ sending: false });
        }
    };

    handleTextChange(changeEvent) {
        this.setState({
            text: changeEvent.target.value
        });
    };

    handleEmailChange(changeEvent) {
        this.setState({
            email: changeEvent.target.value
        });
    };

    result(text) {
        this.setState({
            captcha: text
        })
    }

    render() {
        return (
            <div>
                <div style={{ marginTop: "20px", marginBottom: "15px" }}>
                    <img src={logo} alt="Logos" style={{ width: 770 }} />
                </div>
                {/*<div style={{ display: "flex", justifyContent: "flex-end" }}>*/}
                {/*    <img src={euFlag} alt="EU flag" style={{ height: 40, marginLeft: 5, cursor: "pointer" }} id="en" onClick={(event) => this.setState({ language: event.target.id })} />*/}
                {/*    <img src={czechiaFlag} alt="Czechia flag" style={{ height: 40, marginLeft: 5, cursor: "pointer" }} id="cz" onClick={(event) => this.setState({ language: event.target.id })}/>*/}
                {/*    <img src={slovakiaFlag} alt="Slovakia flag" style={{ height: 40, marginLeft: 5, cursor: "pointer" }} id="sk" onClick={(event) => this.setState({ language: event.target.id })}/>*/}
                {/*    <img src={polandFlag} alt="Poland flag" style={{ height: 40, marginLeft: 5, cursor: "pointer" }} id="pl" onClick={(event) => this.setState({ language: event.target.id })}/>*/}
                {/*    <img src={hungaryFlag} alt="Hungary flag" style={{ height: 40, marginLeft: 5, cursor: "pointer" }} id="hu" onClick={(event) => this.setState({ language: event.target.id })} />*/}
                {/*    <img src={russiaFlag} alt="Russia flag" style={{ height: 40, marginLeft: 5, cursor: "pointer" }} id="ru" onClick={(event) => this.setState({ language: event.target.id })}/>*/}
                {/*    <img src={ukraineFlag} alt="Ukraine flag" style={{ height: 40, marginLeft: 5, cursor: "pointer" }} id="ua" onClick={(event) => this.setState({ language: event.target.id })}/>*/}
                {/*    <img src={romaniaFlag} alt="Romania flag" style={{ height: 40, marginLeft: 5, cursor: "pointer" }} id="ro" onClick={(event) => this.setState({ language: event.target.id })}/>*/}
                {/*</div>*/}
                <div style={{ background: '#f19500', overflow: "auto", marginBottom: 30, color: 'white', padding: 40, borderRadius: "5px" }}>
                    <h2>{LanguageVersions[this.state.language].heading}</h2>
                    <p>{LanguageVersions[this.state.language].welcome}
                        <br />
                        <div dangerouslySetInnerHTML={{ __html:LanguageVersions[this.state.language].hotline }} />
                        {LanguageVersions[this.state.language].conduct} <a href={codeOfConduct} target="_blank" rel="noreferrer">Code of Conduct</a>.
                    </p>
                </div>
                {/*<form onSubmit={this.handleFormSubmit}>*/}
                {/*    <label>{LanguageVersions[this.state.language].question1}</label>*/}
                {/*    <div className="form-check">*/}
                {/*        <label className="form-check-label">*/}
                {/*            <input className="form-check-input" type="radio" value="Discrimination & Harassment" checked={this.state.selectedOption === 'Discrimination & Harassment'} onChange={this.handleOptionChange} />*/}
                {/*            {LanguageVersions[this.state.language].discrimination}*/}
                {/*</label>*/}
                {/*    </div>*/}
                {/*    <div className="form-check">*/}
                {/*        <label className="form-check-label">*/}
                {/*            <input className="form-check-input" type="radio" value="Fraud & Corruption" checked={this.state.selectedOption === 'Fraud & Corruption'} onChange={this.handleOptionChange} />*/}
                {/*            {LanguageVersions[this.state.language].corruption}*/}
                {/*</label>*/}
                {/*    </div>*/}
                {/*    <div className="form-check">*/}
                {/*        <label className="form-check-label">*/}
                {/*            <input className="form-check-input" type="radio" value="Environment, Health & Safety" checked={this.state.selectedOption === 'Environment, Health & Safety'} onChange={this.handleOptionChange} />*/}
                {/*            {LanguageVersions[this.state.language].environment}*/}
                {/*</label>*/}
                {/*    </div>*/}
                {/*    <div className="form-check">*/}
                {/*        <label className="form-check-label">*/}
                {/*            <input className="form-check-input" type="radio" value="Employee Misconduct" checked={this.state.selectedOption === 'Employee Misconduct'} onChange={this.handleOptionChange} />*/}
                {/*            {LanguageVersions[this.state.language].misconduct}*/}
                {/*</label>*/}
                {/*    </div>*/}
                {/*    <div className="form-check">*/}
                {/*        <label className="form-check-label">*/}
                {/*            <input className="form-check-input" type="radio" value="Other" checked={this.state.selectedOption === 'Other'} onChange={this.handleOptionChange} />*/}
                {/*            {LanguageVersions[this.state.language].other}*/}
                {/*</label>*/}
                {/*    </div>*/}

                {/*    <div className="form-group" style={{ marginTop: 30 }}>*/}
                {/*        <label>{LanguageVersions[this.state.language].question2}</label>*/}
                {/*        <textarea className={this.state.text === '' ? 'form-control is-invalid' : 'form-control'} value={this.state.text} onChange={this.handleTextChange} style={{ minHeight: 120 }} required>*/}
                {/*        </textarea>*/}
                {/*        <div className="invalid-feedback">*/}
                {/*            {LanguageVersions[this.state.language].errorMessage}*/}
                {/* </div>*/}
                {/*    </div>*/}

                {/*    <div className="" style={{ marginTop: 30 }}>*/}
                {/*        <label>{LanguageVersions[this.state.language].question3}</label>*/}
                {/*        <input type="text" className={this.state.isEmailValid ? 'form-control' : 'form-control is-invalid'} value={this.state.email} onChange={this.handleEmailChange} />*/}
                {/*        <div className="invalid-feedback">*/}
                {/*            {LanguageVersions[this.state.language].errorMessage2}*/}
                {/* </div>*/}
                {/*    </div>*/}

                {/*    <div style={{ display: "flex", alignItems: "center", float: "right", marginBottom: 50, minHeight: 110 }}>*/}
                {/*        <RCG result={this.result} key={this.state.counter} />*/}
                {/*        {*/}
                {/*            <div style={{ alignSelf: "stretch", paddingTop: 15 }} onClick={e => { e.preventDefault(); this.setState({ counter: this.state.counter + 1, enterCaptcha: ' ' }); }}>*/}
                {/*                <svg class="bi bi-arrow-counterclockwise" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">*/}
                {/*                    <path fill-rule="evenodd" d="M12.83 6.706a5 5 0 0 0-7.103-3.16.5.5 0 1 1-.454-.892A6 6 0 1 1 2.545 5.5a.5.5 0 1 1 .91.417 5 5 0 1 0 9.375.789z" />*/}
                {/*                    <path fill-rule="evenodd" d="M7.854.146a.5.5 0 0 0-.708 0l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 1 0 .708-.708L5.707 3 7.854.854a.5.5 0 0 0 0-.708z" />*/}
                {/*                </svg>*/}
                {/*            </div>*/}
                {/*        }*/}
                {/*        <input type='text' style={{ width: 100, marginLeft: 5 }} className={this.state.enterCaptcha === ' ' ? 'form-control' : this.state.enterCaptcha !== this.state.captcha ? 'form-control is-invalid' : 'form-control'} value={this.state.enterCaptcha} onChange={t => this.setState({ enterCaptcha: t.target.value.trim() })} />*/}
                {/*        <button disabled={this.state.sending} className="btn btn-primary" type="submit" style={{ background: '#f19500', borderColor: '#f19500', marginLeft: 20 }}>{LanguageVersions[this.state.language].submit}</button>*/}
                {/*    </div>*/}
                {/*</form>*/}
            </div >
        );
    }
}
