const LanguageVersions = {
    en: {
        heading: 'POS Media Ethics Hotline',
        welcome: "Do you suspect unethical behavior in our organization or want to send us any feedback? Use our whistleblowing channel.",
        conduct: 'For further reference please visit our',
        hotline: "Our company values ethical behavior and mutual respect. In case you witness unethical behavior that is difficult to discuss personally, we have established a <a href=https://www.nntb.cz/c/posmedia>Whistleblowing channel</a>. Through this channel, you can safely send reports of illegal or unethical behavior, improvement suggestions, questions, or any feedback.<br />All reports are handled by designated persons in our organization. Information sent through the whistleblowing channel is completely secure and there is no risk of retaliation.",
        question1: "1. Please select the most relevant category you'd like to report on:",
        discrimination: 'Discrimination & Harassment',
        corruption: 'Fraud & Corruption',
        environment: 'Environment, Health & Safety',
        misconduct: 'Employee Misconduct',
        other: 'Other',
        question2: '2. Please describe the incident with relevant details and press "Submit"',
        errorMessage: 'For a successful submission the text description must not be empty.',
        question3: "3. In case you'd like to receive feedback on the development of your submission, please enter your e-mail address below. If you prefer anonymity, we advise you to create a specific anonymous mailbox offered by a free provider (gmail.com, seznam.cz), e.g. submission.one@gmail.com, and enter this mailbox in the field below.",
        errorMessage2: "Please kindly check correct format of entered e-mail (address@domain.com).",
        submit: 'Submit',
        success: 'Submission successful.',
        thanks: 'We greatly appreciate your integrity and courage for coming forward.',
        review: 'Our compliance supervisory team will review the issue right away.',
        errorSubmit: "Failure to submit. Please kindly inform the front desk of any of our companies."
    },
    cz: {
        heading: 'POS Media Group – Etická linka',
        welcome: 'Vítejte na naší globální Etické lince, kterou jsme založili v rámci našich trvalých snah k zajištění etického standardu, ke snížení rizika neetického nebo protiprávního jednání, a k zajištění souladu se skupinovými předpisy.',
        conduct: 'Pro detail můžete navštívit náš skupinový etický kodex',
        hotline: 'Tato etická linka je diskrétní nástroj určený pro jakéhokoliv obchodního partnera, zaměstnance nebo jiné zainteresované strany, a slouží k anonymnímu sdílení informací o porušení našich směrnic či jiného protiprávního jednání. Odeslaná hlášení jsou následně předložena týmu pro dohled nad dodržováním zásad etiky k prověření a zvážení dalšího postupu, případně pak zapojení příslušného člena skupinového vedení.',
        question1: '1. Vyberte prosím kategorii, která nejlépe odpovídá Vašemu hlášení:',
        discrimination: 'Diskriminace a obtěžování',
        corruption: 'Podvody a korupce',
        environment: 'Životní prostředí a BOZP',
        misconduct: 'Nevhodné chování zaměstnanců / k zaměstnancům',
        other: 'Jiné',
        question2: '2. Popište prosím předmětný incident včetně relevantních detailů a stiskněte „Potvrdit“',
        errorMessage: 'Pro úspěšné podání nesmí být textové pole prázdné.',
        question3: 'V případě, že byste chtěli získat zpětnou vazbu o vývoji a vyřešení daného problému, uveďte prosím e-mailovou adresu, na které Vás můžeme informovat. V případě, že si chcete zachovat úplnou anonymitu, doporučujeme si založit zvláštní e-mailovou schránku pro tento účel u některého z bezplatných poskytovatelů e-mailových služeb (gmail.com, seznam.cz), např. podani.001@gmail.com, a takovou adresu pak uveďte v poli níže.',
        errorMessage2: 'Zkontrolujte prosím správnost formátu zadané e-mailové adresy (tj. např. adresa@domena.cz).',
        submit: 'Potvrdit',
        success: 'Podání bylo úspěšné.',
        thanks: 'Děkujeme za projevenou odvahu a důvěru se na nás obrátit.',
        review: 'Náš tým dohledu nad dodržováním zásad etiky Vaše podání prověří bez zbytečného prodlení.',
        errorSubmit: "Odeslání selhalo. Prosíme, abyste o této skutečnosti informovali recepci naší nejbližší pobočky. Děkujeme."
    },
    sk: {
        heading: 'POS Media etická horúca linka',
        welcome: 'Implementovali sme globálnu dôvernú etickú horúcu linku ako súčasť nášho nepretržitého úsilia o presadzovanie dodržiavania pravidiel, etického správania sa a dodržiavania skupinovej politiky.',
        conduct: 'Ďalšie informácie nájdete v našom Kódexe správania.',
        hotline: 'Horúca linka pre etiku je dôverný nástroj, ktorý je možné použiť pre ktoréhokoľvek obchodného partnera, zamestnanca alebo inú zainteresovanú stranu na anonymné zdieľanie informácií o akomkoľvek porušení našich politík alebo príslušného právneho rámca. Po predložení nahlásených formulárov prejdete priamo na Etickú komisiu, ktorá záležitosť preverí a prediskutuje / zapojí príslušného vysoko postaveného úradníka.',
        question1: '1. Prosím vyberte najrelevantnejšiu kategóriu, ktorú chcete nahlásiť:',
        discrimination: 'diskriminácia a obťažovanie',
        corruption: 'podvody a korupcia',
        environment: 'životné prostredie, zdravie a bezpečnosť',
        misconduct: 'nevhodné správanie zamestnancov',
        other: 'iné',
        question2: '2. Popíšte incident s relevantnými podrobnosťami a stlačte „Odoslať“.',
        errorMessage: 'Pre úspešné odoslanie nesmie byť textový popis prázdny.',
        question3: 'V prípade, že by ste chceli získať spätnú väzbu o spracovaní Vášho podania, prosím zadajte svoju e-mailovú adresu. Ak dávate prednosť anonymite, odporúčame Vám vytvoriť si konkrétnu anonymnú e-mailovú schránku.',
        errorMessage2: 'Prosím skontrolujte správny format zadaného e-mailu (adresa@domena.com).',
        submit: 'Odoslať',
        success: 'Odoslanie bolo úspešné.',
        thanks: 'Veľmi si ceníme Vašu integritu a odvahu ozvať sa.',
        review: 'Naši pracovníci oddelenia HQ Compliance túto záležitosť okamžite preskúmajú.',
        errorSubmit: 'Odoslanie zlyhalo. Prosím informujte recepciu v ktorejkoľvek z našich spoločností o chybe webovej stránky.'
    },
    pl: {
        heading: 'Infolinia ds. etyki POS Media',
        welcome: 'W ramach naszych działań mających wspierać przestrzeganie zasad naszej Grupy i promować etyczne postępowanie, wdrożyliśmy globalną, poufną infolinię ds. Etyki.',
        conduct: 'Więcej informacji można znaleźć w naszym Kodeksie postępowania.',
        hotline: 'Infolinia ds. etyki jest poufnym narzędziem, z którego może korzystać każdy partner biznesowy, pracownik lub inny zainteresowany, aby anonimowo udostępniać informacje o wszelkich naruszeniach naszych zasad lub obowiązujących ram prawnych. Zgłoszone formularze, po przesłaniu, trafiają bezpośrednio do Komisji Etyki, która zbada sprawę i omówi/zaangażuje w nią odpowiedniego członka kadry kierowniczej.',
        question1: '1. Wybierz najbardziej odpowiednią kategorię, na temat której chcesz wysłać zgłoszenie:',
        discrimination: 'Dyskryminacja i Nękanie',
        corruption: 'Oszustwa i Korupcja',
        environment: 'Środowisko, Zdrowie i Bezpieczeństwo',
        misconduct: 'Niewłaściwe postępowanie pracownika',
        other: 'Inny',
        question2: '2. Opisz zdarzenie dodając istotne szczegóły i naciśnij „Prześlij”',
        errorMessage: 'Aby przesłać zgłoszenie, opis tekstowy nie może być pusty.',
        question3: '3. Jeśli chcesz otrzymać opinię zwrotną na temat dalszego przebiegu Twojego zgłoszenia, proszę wprowadzić poniżej swój adres e-mail. Jeśli wolisz pozostać anonimowy, radzimy utworzyć specjalną anonimową skrzynkę pocztową oferowaną przez darmowego dostawcę (gmail.com, seznam.cz), np.: zgłoszenie.jeden@gmail.com i wpisać adres tej skrzynki pocztowej w polu poniżej',
        errorMessage2: 'Prosimy o sprawdzenie poprawności formatu wpisanego adresu e-mail (adres@domena.com).',
        submit: 'Prześlij',
        success: 'Zgłoszenie zostało przesłane pomyślnie.',
        thanks: 'Jesteśmy bardzo wdzięczni za twoją uczciwość i odwagę do przesłania zgłoszenia.',
        review: 'Nasz personel ds. Zgodności z Przepisami w Centrali niezwłocznie przeanalizuje problem',
        errorSubmit: 'Przesłanie zgłoszenia nie powiodło się. Uprzejmie prosimy o poinformowanie dowolnej recepcji jednej z naszych firm o błędzie na tej stronie.'
    },
    hu: {
        heading: 'POS Media Etikai Forródrót',
        welcome: 'A jogszabályoknak történő megfelelés, az etikus viselkedés és magatartás előmozdítására irányuló folyamatos erőfeszítéseink részeként globális bizalmas etikai forródrótot vezettünk be, összhangban a Cégcsoport szabályzataival.',
        conduct: 'További információkért látogassa meg Magatartási Kódexünket.',
        hotline: 'Az etikai forródrót egy bizalmasan működő eszköz, amely bármely üzleti partner, alkalmazott vagy más érdekelt fél számára anonim módon biztosítja a lehetőséget, hogy a Cégcsoport irányelveinek vagy az alkalmazandó jogi keretek bármilyen megsértésével kapcsolatban bejelentést tegyen. A beküldött nyomtatványok a benyújtást követően közvetlenül az Etikai Bizottsághoz kerülnek, amely kivizsgálja az adott ügyet és megvitatja / bevonja a megfelelő szintű vezetőt vagy ügyvezetőt',
        question1: '1. Válassza ki a legrelevánsabb kategóriát, amelyről jelentést szeretne adni',
        discrimination: 'Diszkrimináció és zaklatás',
        corruption: 'Csalás és korrupció',
        environment: "Környezet, egészség és biztonság",
        misconduct: 'Munkavállalói kötelességszegés',
        other: 'más',
        question2: '2. Kérjük, írja le az eseményt kiemelve a legfontosabb részleteket, és nyomja meg a „Küldés” gombot',
        errorMessage: 'A sikeres beküldéshez szükséges, hogy a szöveges leírás ne legyen üres!',
        question3: 'Amennyiben szeretne visszajelzést kapni a jelentése előrehaladásáról, úgy kérjük lentebb adja meg e-mail címét. Amennyiben szeretné névtelenségét megőrizni, azt javasoljuk, hogy hozzon létre egy speciális névtelen postafiókot valamely ingyenes szolgáltatónál (gmail.com, seznam.cz), például: jelentes.egy@gmail.com és ezt a postafiókot adja meg a lentebbi mezőben.',
        errorMessage2: 'Kérjük, ellenőrizze a beírt e-mail cím helyes formátumát (cím@domain.com).',
        submit: 'Küldés',
        success: 'A beküldés sikeres!',
        thanks: 'Nagyon nagyra értékeljük az Ön becsületességét és bátor fellépését!',
        review: 'A Központi Megfelelési munkatársaink hamarosan megvizsgálják az Ön által bejelentett problémát!',
        errorSubmit: 'A beküldés sikertelen. Kérjük, tájékoztassa bármelyik cégünk recepcióját a weboldal hibájáról.'
    },
    ua: {
        heading: 'Гаряча лінія норм поведінки POS медіа',
        welcome: 'Ми впровадили глобальну конфіденційну гарячу лінію з питань норм поведінки в рамках наших постійних зусиль, спрямованих на забезпечення відповідності, етичної поведінки та дотримання політики нашої компанії.',
        conduct: 'Для отримання більш детальної інформації, будь ласка, ознайомтеся з нормами поведінки.',
        hotline: 'Гаряча лінія з питань етики - це конфіденційний інструмент, доступний будь-якому діловому партнерові, співробітнику або інший зацікавленій стороні для анонімного обміну інформацією про будь-яке порушення нашої політики або застосовних правових норм. Після подання заповнена форма відправляється зразу до Комітету норм поведінки, який дослідить звернення і обговорить / залучить відповідного старшого виконавчого директора.',
        question1: '1. Будь ласка, виберіть найбільш відповідну категорію, про яку Ви хотіли б повідомити:',
        discrimination: 'Дискримінація і домагання',
        corruption: 'Шахрайство і корупція',
        environment: "Оточення, здоров'я та безпеку",
        misconduct: 'Провина працівника',
        other: 'Інше',
        question2: '2. Будь ласка, опишіть інцидент з відповідними деталями і натисніть "Відправити".',
        errorMessage: 'Для успішного подання текстовий опис має бути заповнений',
        question3: "3. У разі, якщо Ви хочете отримати зворотній зв'язок по Вашій заявці, будь ласка, вкажіть Вашу електронну адресу нижче.Якщо Ви віддаєте перевагу анонімності, радимо Вам створити спеціальну анонімну поштову скриньку, що пропонують безкоштовні провайдери(gmail.com, seznam.cz), наприклад, submission.one@gmail.com, і ввести цю поштову скриньку в поле нижче.",
        errorMessage2: 'Будь ласка, перевірте правильність формату введеної електронної пошти (address@domain.com).',
        submit: 'Відправити',
        success: 'Відправка пройшла успішно',
        thanks: 'Ми дуже цінуємо Ваше звернення за чесність і сміливість.',
        review: 'Наші співробітники з головного відділу звернень відразу ж розглянуть Ваше питання.',
        errorSubmit: 'Подання не вдалося. Будь ласка, повідомте адміністрацію компанії про помилку веб-сайту.'
    },
    ru: {
        heading: 'Горячая линия ПОС Медиа по вопросам этики',
        welcome: 'Мы внедрили глобальную горячую линию по конфиденциальной этике в рамках наших постоянных усилий по продвижению комплаенса, этического поведения и приверженности политике нашей группы.',
        conduct: 'Для получения дополнительной информации, пожалуйста, посетите наш Кодекс поведения.',
        hotline: 'Горячая линия по этике-это конфиденциальный инструмент, доступный для использования любым деловым партнерам, сотрудникам или другой заинтересованной стороной для анонимного обмена информацией о любом нарушении нашей политики или применимой правовой базы. Отчетные формы, как только они будут представлены, направляются непосредственно в Комитет по этике, который будет расследовать этот вопрос и обсуждать/привлекать соответствующего старшего исполнительного сотрудника.',
        question1: '1. Пожалуйста, выберите наиболее релевантную категорию, о которой вы хотели бы сообщить:',
        discrimination: 'Дискриминация И Притеснения',
        corruption: 'Мошенничество И Коррупция',
        environment: 'Окружающая Среда, Здоровье И Безопасность',
        misconduct: 'Нарушения Трудовой Дисциплины Работниками',
        other: 'Другое',
        question2: '2. Пожалуйста, опишите инцидент с соответствующими подробностями и нажмите кнопку "Отправить"',
        errorMessage: 'Для успешной отправки текстовое описание не должно быть пустым',
        question3: '3. Если вы хотите получить обратную связь о развитии вашей заявки, пожалуйста, введите свой адрес электронной почты ниже. Если вы предпочитаете анонимность, мы рекомендуем вам создать специальный анонимный почтовый ящик, предлагаемый бесплатным поставщиком услуг (gmail.com, seznam.cz), напр. submission.one@gmail.com, и введите этот почтовый ящик в поле ниже.',
        errorMessage2: 'Пожалуйста, проверьте правильный формат введенного электронного письма (address@domain.com).',
        submit: 'Отправить',
        success: 'Ваша заявка принята.',
        thanks: 'Мы очень ценим вашу честность и мужество за ш отклик.',
        review: 'Наши сотрудники Головного офиса, ответственные за по соблюдение требований рассмотрят этот вопрос сразу же.',
        errorSubmit: 'Заявление не удалось отправить. Пожалуйста сообщите в службу поддержки любой из наших компаний об ошибке на сайте.'
    },
    ro: {
        heading: 'Linia directa de Etică a POS Media',
        welcome: 'Am implementat o linie confidentiala globala de Etică, ca parte a eforturilor noastre continue de a promova conformitatea, comportamentul etic, conduita și aderarea la politicile Grupului nostru.',
        conduct: 'Pentru referințe suplimentare, vă rugăm să vizitați Codul de Conduită.',
        hotline: 'Linia directa de Etică este un instrument confidential, disponibil pentru a fi utilizat de orice partener de afaceri, angajat sau altă parte interesată pentru a expune în mod anonim informații cu privire la orice încălcare a politicilor noastre sau a cadrului legal aplicabil. Formularele raportate, odată trimise, merg direct la Comitetul de Etică, care va investiga problema și va discuta / va implica Directorul Executiv superior.',
        question1: '1. Vă rugăm să selectați cea mai relevantă categorie despre care doriți să raportați:',
        discrimination: 'Discriminare și hărțuire',
        corruption: 'Fraudă și corupție',
        environment: 'Mediu, sănătate și siguranță',
        misconduct: 'Conduită incorectă a angajaților',
        other: 'Altele',
        question2: '2. Vă rugăm să descrieți incidentul cu detalii relevante și apăsați pe „Trimiteți”',
        errorMessage: 'Pentru o trimitere reușită, descrierea textului nu trebuie să fie goală.',
        question3: '3. În cazul în care doriți să primiți feedback cu privire la situatia trimiterii dumneavoastra, vă rugăm să introduceți adresa de e-mail mai jos. Dacă preferați anonimatul, vă sfătuim să creați o adresa de mail anonimă oferită de un furnizor gratuit (gmail.com, yahoo.com, etc), de ex. submission.one@gmail.com și introduceți această adresa în câmpul de mai jos.',
        errorMessage2: 'Vă rugăm să verificați formatul corect al e-mailului introdus (address@domain.com).',
        submit: 'Trimiteți',
        success: 'Trimiterea a reușit.',
        thanks: 'Apreciem foarte mult integritatea și curajul dumneavoastra pentru detaliile oferite.',
        review: 'Echipa noastră de supraveghere a conformității din cadrul Comitetului de Etica, va examina problema imediat.',
        errorSubmit: 'Conexiunea a esuat. Va rugam sa informati receptia oricareia dintre companiile noatre despre aceasta eroare a siteului.'
    },
}

export default LanguageVersions;